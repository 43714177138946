import { toUnix } from 'lib/utils/dates'

const getInitial = (list, inquiryValue) => {
  if (inquiryValue) {
    const isValidLocation = list.some((item) => item.name === inquiryValue)

    if (isValidLocation) {
      return inquiryValue
    }
  }

  return list.length > 0 ? list[0].name : ''
}

export const getInitialLocation = (locations, inquiry) =>
  getInitial(locations, inquiry.location)

export const getInitialCategory = (categories, inquiry) =>
  getInitial(categories, inquiry.eventType)

export const getInitialDate = (inquiry) => {
  if (inquiry?.dateTime?.date) {
    return inquiry.dateTime.date
  }

  return toUnix(new Date())
}

export default {
  getInitialLocation,
  getInitialCategory,
  getInitialDate,
}
