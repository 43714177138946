import { useState, useEffect } from 'react'

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    // this is done only because nextjs ssr window is undefined thus exempting the use of a dedicated function
    // although there is no need to do typeof check because useEffect is only executed in client-side.
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowDimensions
}

export default useWindowDimensions
