import { useRef } from 'react'
import Slider from 'react-slick'
import Link from 'components/Navigation/WrapperLink'
import Image from 'components/Image'
import { sliderSettings } from './settings'
import styles from './Images.module.scss'

export default function Images({ image, images, link }) {
  const sliderRef = useRef()

  if (!images || images.length === 0) {
    return (
      <div className={styles.image}>
        <Link data={{ slug: link, type: 'event' }}>
          <Image image={image} size="teaser-retina" layout="responsive" />
        </Link>
      </div>
    )
  }

  return (
    <div className={styles.images}>
      <div
        className={styles.controlsLeft}
        onClick={() => sliderRef.current.slickPrev()}
      >
        <img
          src="/img/arrow-right-white.svg"
          role="button"
          className={styles.prev}
          width={37}
          height={37}
          loading="lazy"
          alt="Edellinen kuva"
        />
      </div>
      <div
        className={styles.controlsRight}
        onClick={() => sliderRef.current.slickNext()}
      >
        <img
          src="/img/arrow-right-white.svg"
          role="button"
          className={styles.next}
          width={37}
          height={37}
          loading="lazy"
          alt="Seuraava kuva"
        />
      </div>

      <Link data={{ slug: link, type: 'event' }}>
        <Slider ref={sliderRef} {...sliderSettings}>
          <Image image={image} size="teaser-retina" layout="responsive" />
          {images.map((galleryImage, index) => (
            <Image
              key={`${galleryImage.id}-${index}`}
              image={galleryImage}
              size="teaser-retina"
              layout="responsive"
            />
          ))}
        </Slider>
      </Link>
    </div>
  )
}
