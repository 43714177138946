import Link from 'components/Link'
import styles from './CTA.module.scss'

export default function CTA({ section }) {
  const { title, content, button } = section

  /**
   * If CTA does not have title,
   * the main content should be bigger.
   */
  const getContentClasses = () => {
    if (!title) {
      return styles.bigText
    }

    return 'small'
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.wrap}>
          <header className={styles.header}>
            {title && <h4 className={styles.title}>{title}</h4>}
            {content && <p className={getContentClasses()}>{content}</p>}
          </header>

          {button && (
            <Link
              className={`btn btn--primary ${styles.button}`}
              link={button}
            />
          )}

          <img
            alt=""
            role="presentation"
            className={styles.decoration}
            src="/img/happens.svg"
          />
        </div>
      </div>
    </section>
  )
}
