import React, { useEffect } from 'react'
import styles from './HubspotContactForm.module.scss'

export default function HubspotContactForm({ section }) {
  const { portalId, formId } = section

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId,
          formId,
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <section className={styles.section}>
      <div className={`container`}>
        <div className="row" id="hubspotForm"></div>
      </div>
    </section>
  )
}
