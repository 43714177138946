import Image from 'components/Image'
import styles from './FiftyFiftyHero.module.scss'

export default function FiftyFiftyHero({ section }) {
  const {
    title,
    content,
    image,
    link,
    secondary_links: secondaryLinks,
  } = section

  const formatLink = (optionalLink) => {
    const parts = optionalLink.url.split('/')
    if (parts.length >= 2) {
      return `${parts[parts.length - 2]}`
    }
    return 'https://happens.fi'
  }
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.contentWrap}>
            {title && <h1 className={styles.title}>{title}</h1>}

            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
            <div className={styles.linksWrap}>
              {link && (
                <a
                  className={styles.linkButton}
                  style={{ marginRight: '1rem' }}
                  href={link.url}
                  target={link.target}
                >
                  {link.title}
                </a>
              )}

              {secondaryLinks && secondaryLinks.length > 0 && (
                <>
                  {secondaryLinks.map((lnk, index) => (
                    <>
                      {lnk &&
                        lnk.secondary_link &&
                        typeof lnk.secondary_link === 'string' &&
                        lnk.secondary_link.includes('/') && (
                          <a
                            key={`${lnk.secondary_link.title} + ${index}`}
                            className={styles.linkButton}
                            style={{ marginRight: '1rem' }}
                            href={formatLink(lnk.secondary_link)}
                            target={lnk.secondary_link.target}
                          >
                            {lnk.secondary_link.title}
                          </a>
                        )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className={styles.imageWrap}>
            <div className={styles.imageFrame}>
              <Image image={image} size="50-50-retina" layout="responsive" />
            </div>
          </div>

          <img
            className={styles.decoration}
            alt=""
            role="presentation"
            src="/img/happens.svg"
          />
        </div>
      </div>
    </section>
  )
}
