import React, { useRef } from 'react'
import Slider from 'react-slick'
import useWindowDimensions from '../../lib/services/ViewPort/ViewPort'
import styles from './SliderContainer.module.scss'
import { settings } from '../Section/RelatedEvents/settings'
import EventTeaser from '../Teasers/Event'

const SliderContainer = ({ events }) => {
  const { width } = useWindowDimensions()
  const sliderRef = useRef()
  const showControls = events.length > 3
  return (
    <div className={styles.sliderRow}>
      {width >= 1200 && showControls && (
        <img
          src="/img/next-icon.svg"
          role="button"
          alt="Edelliset tapahtumat"
          onClick={() => sliderRef.current.slickPrev()}
          className={styles.prev}
          width={37}
          height={37}
          loading="lazy"
        />
      )}
      <div className={styles.sliderContainer}>
        <Slider ref={sliderRef} {...settings}>
          {events.map((event) => (
            <EventTeaser key={event.id} event={event} />
          ))}
        </Slider>
      </div>
      {width >= 1200 && showControls && (
        <img
          src="/img/next-icon.svg"
          role="button"
          alt="Seuraavat tapahtumat"
          onClick={() => sliderRef.current.slickNext()}
          className={styles.next}
          width={37}
          height={37}
          loading="lazy"
        />
      )}
    </div>
  )
}

export default SliderContainer
