import Image from 'components/Image'
import styles from './DefaultHero.module.scss'

export default function DefaultHero({ section }) {
  const { image, title = null, text = null } = section

  return (
    <section className={`container ${styles.section}`}>
      <div className={styles.imgWrap}>
        <div className={styles.mobileImg}>
          <Image
            image={image}
            size="teaser-retina"
            layout="responsive"
            objectPosition="center center"
          />
        </div>
        <div className={styles.desktopImg}>
          <Image
            image={image}
            size="2048x2048"
            layout="responsive"
            objectPosition="center center"
          />
        </div>
        <div className={styles.contentWrap}>
          <header className="container">
            {title && <h1 className={styles.title}>{title}</h1>}
            {text && <p className={styles.text}>{text}</p>}
          </header>
        </div>
      </div>
    </section>
  )
}
