import { useState, useEffect } from 'react'
import { toUnix, fromUnix } from 'lib/utils/dates'
import { getGeneralInquiry } from 'lib/services/inquiry'
import Select from 'components/EventFilters/Select'
import styles from './EventFilters.module.scss'

export default function EventFilters({
  filters,
  callback,
  initialDate = new Date(),
  design = 'primary',
  useStore = true,
}) {
  const { locations, categories, capacities } = filters
  const [location, setLocation] = useState(locations[0].name)
  const [category, setCategory] = useState(categories[0].name)
  const [capacity, setCapacity] = useState(capacities[0].name)
  const [date, setDate] = useState(initialDate)

  /**
   * Pass filter data to callback,
   * May be used differently in different pages.
   */
  const handleFilters = () => {
    const data = {
      location,
      eventType: category,
      guests: capacity,
      dateTime: {
        date: toUnix(date),
      },
    }

    callback(data)
  }

  /**
   * Get wrapper classes by design prop.
   */
  const getClasses = () => {
    if (design !== 'primary') {
      return `${styles.section} ${styles.alternative}`
    }

    return styles.section
  }

  /**
   * Fetch selected filter values from store
   */
  useEffect(() => {
    if (useStore) {
      const inquiry = getGeneralInquiry()

      setLocation(inquiry.location)
      setCategory(inquiry.eventType)
      setCapacity(inquiry.guests)
      setDate(fromUnix(inquiry.dateTime.date))
    }
  }, [useStore])

  return (
    <>
      <section className={getClasses()}>
        <div className={styles.filter}>
          <label htmlFor="location" className={styles.label}>
            Sijainti
            <Select
              type="location"
              options={locations}
              active={location}
              callback={setLocation}
              design={design}
            />
          </label>
        </div>
        <div className={styles.filter}>
          <label htmlFor="eventType" className={styles.label}>
            Tapahtuma
            <Select
              type="eventType"
              options={categories}
              active={category}
              callback={setCategory}
              design={design}
            />
          </label>
        </div>
        <div className={styles.filter}>
          <label htmlFor="guests" className={styles.label}>
            Henkilömäärä
            <Select
              type="guests"
              options={capacities}
              active={capacity}
              callback={setCapacity}
              design={design}
            />
          </label>
        </div>

        <section className={styles.btnWrap}>
          <button
            className={`btn btn--primary ${styles.submit}`}
            onClick={handleFilters}
          >
            Hae
          </button>
        </section>
      </section>
    </>
  )
}
