import NextImage from 'next/image'

export default function Image({
  image,
  size,
  layout = 'intrinsic',
  objectFit = 'cover',
  objectPosition = 'top left',
}) {
  if (!image || !image.ID) {
    return null
  }

  const { sizes, url: original } = image
  const isRetina = size.includes('-retina')
  const nonRetinaSize = size.replace('-retina', '')

  /**
   * Get image src with sane fallbacks.
   * -> If looking for non-existing retina, use non-retina
   */
  const getSrc = () => {
    if (isRetina) {
      const noRetinaAvailable = sizes[size] === original

      if (noRetinaAvailable) {
        return sizes[nonRetinaSize]
      }
    }

    return sizes[size]
  }

  /**
   * Define alt tag for image.
   * --> If alt, use it.
   * --> If not, default to caption.
   */
  const getAlt = () => {
    if (image.alt && image.alt !== '') {
      return image.alt
    }

    if (image.caption && image.caption !== '') {
      return image.caption
    }

    return ''
  }

  const getWidth = () => {
    if (isRetina) {
      const noRetinaAvailable = sizes[size] === original

      if (noRetinaAvailable) {
        return sizes[`${nonRetinaSize}-width`]
      }
    }

    return sizes[`${size}-width`]
  }

  const getHeight = () => {
    if (isRetina) {
      const noRetinaAvailable = sizes[size] === original

      if (noRetinaAvailable) {
        return sizes[`${nonRetinaSize}-height`]
      }
    }

    return sizes[`${size}-height`]
  }

  return (
    <NextImage
      src={getSrc()}
      alt={getAlt()}
      role={getAlt() === '' ? 'presentation' : null}
      width={getWidth()}
      height={getHeight()}
      layout={layout}
      objectFit={objectFit}
      objectPosition={objectPosition}
      placeholder="blur"
      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8f3FvPQAIcgMOA2P/0AAAAABJRU5ErkJggg=="
    />
  )
}
