import styles from './Steps.module.scss'

export default function Steps({ section }) {
  const { steps } = section

  if (steps.length === 0) {
    return null
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.wrap}>
          {steps.map((step, index) => (
            <div key={`step-${index}`} className={styles.step}>
              <div className={styles.numberWrap}>
                <div className={styles.number}>{index + 1}</div>
              </div>
              <div className={styles.contentWrap}>
                {step.title && (
                  <h2 className={`gradient-text ${styles.title}`}>
                    {step.title}
                  </h2>
                )}
                {step.content && (
                  <p className={styles.content}>{step.content}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
