export default {
  control: (styles) => ({
    ...styles,
    border: 'none',
    boxShadow: 'none',
    fontSize: '16px',
    lineHeight: '24px',
    minHeight: '24px',
    marginTop: '-4px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 0,
    paddingTop: 0,
    marginTop: 0,
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: '18px',
    lineHeight: '27px',
    marginLeft: '0',
  }),
  input: (styles) => ({ ...styles, padding: '0', margin: '0' }),
  menu: (styles) => ({ ...styles, minWidth: '200px' }),
  groupHeading: (styles, props) => {
    const { data, selectProps } = props
    const isSelected = data.value === selectProps.value.value
    return {
      ...styles,
      textTransform: 'initial',
      marginBottom: '0',
      display: 'flex',
      padding: '0.5rem 0.5rem 0.5rem 1rem',
      fontWeight: 'initial',
      ':hover': {
        backgroundColor: 'rgba(60, 60, 60, 0.05)',
      },
      color: isSelected ? 'RGB(255,	90,	128)' : '#3C3C3C',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: 'inherit',
      '& > .icon': {
        marginLeft: 'auto',
        marginRight: '0.2rem',
        '&-up': {
          transform: 'rotate(180deg)',
        },
      },
    }
  },
  heading: (styles, { isSelected, isFocused }) => ({
    ...styles,
    minWidth: '200px',
    backgroundColor:
      isSelected || isFocused ? 'rgba(60, 60, 60, 0.05)' : 'white',
    color: '#3C3C3C',
  }),
  option: (styles, { data, isSelected }) => ({
    ...styles,
    ':hover': {
      backgroundColor: 'rgba(60, 60, 60, 0.05)',
    },
    backgroundColor: isSelected ? 'rgba(60, 60, 60, 0.05)' : 'white',
    color: isSelected ? 'RGB(255,	90,	128)' : 'initial',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0.5rem',
    paddingLeft: data.parent > 0 ? '2rem' : '1rem',
  }),
}
