import { useEffect } from 'react'
import Link from 'components/Navigation/WrapperLink'
import Slider from 'react-slick'
import CategoryTeaser from 'components/Teasers/Category'
import styles from './Categories.module.scss'

export default function Categories({ section }) {
  const { title, description, categoryLandingPages } = section

  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  })

  /**
   * Run slider in mobile only
   */
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
      },
      {
        breakpoint: 9999999,
        settings: 'unslick',
      },
    ],
  }

  if (!categoryLandingPages || categoryLandingPages.length === 0) {
    return null
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <header className={styles.header}>
          {title && (
            <h2 className={`gradient-text ${styles.title}`}>{title}</h2>
          )}
          {description && <p className={styles.description}>{description}</p>}

          <Link
            data={{ slug: '/tapahtumat', type: 'event_archive' }}
            classes={`btn btn--secondary btn--pink ${styles.headerBtn}`}
          >
            Kaikki tapahtumat
          </Link>
        </header>
        <Slider {...settings} className={styles.wrap}>
          {categoryLandingPages.map((categoryLandingPage) => (
            <CategoryTeaser
              key={categoryLandingPage.slug}
              category={categoryLandingPage}
            />
          ))}
        </Slider>

        <div className={styles.btnWrap}>
          <Link
            data={{ slug: '/tapahtumat', type: 'event_archive' }}
            classes="btn btn--secondary btn--pink"
          >
            Kaikki tapahtumat
          </Link>
        </div>
      </div>
    </section>
  )
}
