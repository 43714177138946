import axios from 'axios'

export const mapFormData = (data, mapping) => {
  const result = []

  Object.entries(data).forEach(([id, answer]) => {
    result.push({
      question: mapping[id],
      answer,
    })
  })

  return result
}

/**
 * Submits form to API endpoint.
 * API endpoint = local node.js endpoint.
 */
export const submitForm = async (data) => {
  const result = await axios({
    method: 'post',
    url: '/api/form',
    data: { form: data },
  })

  return result.data
}

export default {
  mapFormData,
  submitForm,
}
