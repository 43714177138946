import Image from 'components/Image'
import Link from 'components/Navigation/WrapperLink'
import styles from './Category.module.scss'

export default function Category({ category }) {
  const { title, slug, image } = category

  return (
    <article className={styles.teaser}>
      <div className={styles.wrap}>
        <Link data={{ slug: `/${slug}`, type: 'page' }}>
          {image && (
            <div className={styles.imageWrap}>
              <Image image={image} size="teaser-retina" layout="responsive" />
            </div>
          )}
          <div className={styles.contentWrap}>
            <h4 className={styles.title}>{title}</h4>
            <img
              alt=""
              role="presentation"
              className={styles.arrow}
              src="/img/arrow-right.svg"
              width={23}
              height={26}
            />
          </div>
        </Link>
      </div>
    </article>
  )
}
