import styles from './IntroColumns.module.scss'

export default function IntroColumns({ section }) {
  const { header, title, text, columns } = section

  return (
    <section className={styles.section}>
      <div className="container">
        {header && <div className={styles.header}>{header}</div>}
        {title && <h4 className={`gradient-text ${styles.title}`}>{title}</h4>}
        {text && <div className={styles.text}>{text}</div>}
      </div>

      {columns && (
        <div className="container">
          <div className={styles.columnsWrap}>
            {columns.map((column, i) => (
              <div className={styles.column} key={i}>
                <div className={styles.columnTitle}>{column.title}</div>
                <div className={styles.columnText}>{column.text}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  )
}
