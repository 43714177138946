import { subscribeNewsletter } from 'lib/services/newsletter'
import { useState } from 'react'
import { isEmail } from 'lib/utils/validator'
import styles from './NewsletterModifiable.module.scss'

export default function NewsletterModifiable({ section }) {
  const { addContent, content } = section
  const [email, setEmail] = useState('')
  const [subscribed, setSubscribed] = useState(false)
  const [emailIsValid, setEmailIsValid] = useState(false)
  const handleClick = async () => {
    try {
      await subscribeNewsletter({ email })
      setSubscribed(true)
    } catch (e) {
      setEmail('')
      console.log(e)
    }
  }

  const controlEmail = (target) => {
    setEmail(target.value)
    if (isEmail(target.value)) {
      setEmailIsValid(true)
    } else setEmailIsValid(false)
  }

  return (
    <section className={styles.section}>
      <div className={`container ${styles.wrap}`}>
        {!subscribed ? (
          <>
            {addContent === 'Add content' && (
              <p className={`pink-text mb-4 text-500 ${styles.content}`}>
                {content}
              </p>
            )}
            <div className="row-columns-allowed">
              <div className="col-8 me-2">
                <label htmlFor="newsletter-section-email"></label>
                <input
                  id="newsletter-section-email"
                  name="email"
                  type="email"
                  value={email}
                  className={styles.input}
                  placeholder="Sähköpostiosoittesi:"
                  onChange={({ target }) => controlEmail(target)}
                />
              </div>
              <div className="col-4">
                <button
                  className={`${styles.button} btn btn--primary sm-w-100-mobile`}
                  onClick={handleClick}
                  disabled={subscribed || !emailIsValid}
                >
                  Tilaa uutiskirje
                </button>
              </div>
            </div>
            <img
              alt=""
              role="presentation"
              className={styles.decoration}
              src="/img/happy_facing_left_rgb.png"
            />
          </>
        ) : (
          <>
            <h5 className={`${styles.titleSuccess} gradient-text mb-3`}>
              Kiitos tilauksesta!
            </h5>
            <div className={`${styles.imageSuccess}`}>
              <img src="/img/happy-cart.png" width="200" objectFit="contain" />
            </div>
          </>
        )}
      </div>
    </section>
  )
}
