import { useState } from 'react'
import Link from 'components/Navigation/WrapperLink'
import { saveInquiry, getGeneralInquiry } from 'lib/services/inquiry'
import { addToCartEvent } from 'lib/services/gtm'
import { isInCart } from 'lib/services/cart'
import { decodeHtml } from 'lib/utils/decode'
import { getInitialLocation, getInitialCategory, getInitialDate } from './utils'
import EventImages from './Images'
import styles from './Event.module.scss'

export default function Event({ event, linkParam = false }) {
  const {
    id,
    name,
    slug,
    description,
    image,
    images,
    price,
    guests,
    locations = [],
    categories = [],
  } = event
  const [inCart, setInCart] = useState(isInCart(id))

  const getAddToCartText = () => {
    if (inCart) {
      return 'Lisätty koriin'
    }

    return 'Lisää koriin'
  }

  const addToCart = () => {
    const generalInquiry = getGeneralInquiry()
    const locationToUse = getInitialLocation(locations, generalInquiry)
    const categoryToUse = getInitialCategory(categories, generalInquiry)
    const dateToUse = getInitialDate(generalInquiry)

    saveInquiry(id, locationToUse, categoryToUse, dateToUse)
    addToCartEvent(event)
    setInCart(true)
  }

  /**
   * Get event link.
   *
   * Allows appending of query params.
   */
  const getEventLink = (linkSlug) => {
    if (linkParam) {
      return `/${linkSlug}?ref=${linkParam}`
    }

    return `/${linkSlug}`
  }

  return (
    <article className={styles.teaser}>
      <div className={styles.wrap}>
        <EventImages image={image} images={images} link={getEventLink(slug)} />
        <Link data={{ slug: getEventLink(slug), type: 'event' }}>
          <div className={styles.contentWrap}>
            <h4 className={styles.title}>{decodeHtml(name)}</h4>

            <p className={styles.content}>{decodeHtml(description)}</p>

            {guests && (
              <p className={styles.guests}>
                <img
                  className={styles.icon}
                  src="/img/user-pink.svg"
                  width="20"
                  height="20"
                  alt=""
                  role="presentation"
                  loading="lazy"
                />
                {guests}
              </p>
            )}
            <p className={styles.price}>
              <img
                className={styles.icon}
                src="/img/price.svg"
                width="20"
                height="20"
                alt=""
                role="presentation"
                loading="lazy"
              />
              {price}
            </p>
          </div>
        </Link>
        <div className={styles.linksWrap}>
          <p className={styles.link} role="button" onClick={addToCart}>
            {getAddToCartText()}
            {!inCart && (
              <img
                src="/img/cart.svg"
                className={styles.cart}
                alt=""
                role="presentation"
                loading="lazy"
                width={20}
                height={20}
              />
            )}
          </p>
          <Link data={{ slug: getEventLink(slug), type: 'event' }}>
            <button className={`btn btn--primary ${styles.button}`}>
              Lue lisää
            </button>
          </Link>
        </div>
      </div>
    </article>
  )
}
