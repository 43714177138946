import styles from './FeaturesTable.module.scss'

export default function FeaturesTable({ section }) {
  const { title, firstColumnTitle, secondColumnTitle, features } = section

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.titles}>
          {title && <h4 className={styles.title}>{title}</h4>}
          {firstColumnTitle && (
            <div className={styles.subTitle}>{firstColumnTitle}</div>
          )}
          {secondColumnTitle && (
            <div className={styles.subTitle}>{secondColumnTitle}</div>
          )}
        </div>
        {features && (
          <div className={styles.featuresTable}>
            {features.map((feature, i) => (
              <div key={i} className={styles.featureRow}>
                <div className={styles.feature}>{feature.feature}</div>
                <div className={styles.column}>
                  {feature.first && <span className={styles.active}></span>}
                </div>
                <div className={styles.column}>
                  {feature.second && <span className={styles.active}></span>}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
