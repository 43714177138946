import Image from 'components/Image'
import styles from './FiftyFifty.module.scss'

export default function FiftyFifty({ section }) {
  const { title, content, link, image, reverse } = section

  const getReverseClass = () => {
    if (reverse) {
      return styles.reverse
    }

    return null
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={`${styles.contentWrap} ${getReverseClass()}`}>
            {title && <h2 className={styles.title}>{title}</h2>}

            {content && (
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}

            {link && (
              <a href={link.url} target={link.target}>
                {link.title}
              </a>
            )}
          </div>
          <div className={`${styles.imageWrap} ${getReverseClass()}`}>
            <div className={styles.imageFrame}>
              <Image image={image} size="50-50-retina" layout="responsive" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
