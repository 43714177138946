import styles from './Plans.module.scss'

export default function Plans({ section }) {
  const { title, description, plans } = section
  return (
    <section className={styles.section}>
      <div className="container">
        {title && <h4 className={styles.title}>{title}</h4>}
        {description && <div className={styles.description}>{description}</div>}

        {plans && (
          <div className={styles.columnsWrap}>
            {plans.map((plan, i) => (
              <div className={styles.column} key={i}>
                <div
                  className={`${styles.box} ${
                    plan.mostPopular ? styles.boxPopular : ''
                  }`}
                >
                  {plan.mostPopular && (
                    <div className={styles.planPopular}>Suosituin</div>
                  )}
                  {plan.header && (
                    <div className={styles.planHeader}>{plan.header}</div>
                  )}
                  {plan.title && (
                    <h3 className={styles.planTitle}>{plan.title}</h3>
                  )}
                  {plan.price && (
                    <div className={styles.planPrice}>{plan.price}</div>
                  )}
                  {plan.description && (
                    <div className={styles.planHeader}>{plan.description}</div>
                  )}

                  {plan.features && (
                    <div className={styles.features}>
                      {plan.features.map((feature, j) => (
                        <div key={j} className={styles.feature}>
                          {feature.feature}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
