import { useState } from 'react'
import ReactSelect from 'react-select'
import { decodeHtml } from 'lib/utils/decode'
import selectStyles from './select-styles'

const Group = ({ Heading, headingProps, children, label, ...rest }) => {
  const [showChildren, changeShow] = useState(true)
  return (
    <>
      <Heading {...headingProps} {...rest}>
        <span onClick={() => rest.setValue(rest.data)}>{label}</span>
        <span className="icon" onClick={() => changeShow(!showChildren)}>
          <svg height="20" width="20" viewBox="0 0 20 20">
            <path
              transform={showChildren ? 'rotate(180 10 11)' : ''}
              d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
            ></path>
          </svg>
        </span>
      </Heading>
      {showChildren && children}
    </>
  )
}

export default function Select({
  type,
  options,
  active,
  callback,
  design = 'primary',
}) {
  /**
   * Parse options to a format that ReactSelect understands.
   * This component was originally used with a different select solution.
   */
  const childrenOptions = {}
  const selectOptions = options
    .filter((props) => {
      const { parent = 0, name, slug } = props
      const listObj = { value: slug, label: decodeHtml(name), parent }
      if (parent > 0) {
        childrenOptions[parent] = !childrenOptions[parent]
          ? (childrenOptions[parent] = [listObj])
          : [...childrenOptions[parent], listObj]
        return 0
      }
      return 1
    })
    .map((option) =>
      childrenOptions[option.id]
        ? {
            options: childrenOptions[option.id],
            label: decodeHtml(option.name),
            value: option.slug,
          }
        : {
            label: decodeHtml(option.name),
            value: option.slug,
          },
    )

  // Append "other events". Functions same as "all".
  if (type === 'eventType') {
    selectOptions.push({
      label: 'Muut juhlat',
      value: 'muut-juhlat',
    })
  }

  /**
   * Handle value change.
   * Store name of selection to store.
   */
  const handleChange = (value) => {
    callback(value)
  }

  /**
   * Get active option that matches current stored value.
   * Stored active values are names/labels, not slugs.
   */
  const getActive = () => {
    if (active !== '') {
      const item = [
        ...selectOptions,
        ...Object.values(childrenOptions).flat(),
      ].filter((option) => option.label === active)

      if (item.length > 0) {
        return item[0]
      }
    }

    return selectOptions[0]
  }

  return (
    <ReactSelect
      options={selectOptions}
      onChange={({ label }) => {
        handleChange(label)
      }}
      value={getActive()}
      styles={selectStyles}
      classNamePrefix={design !== 'primary' ? 'select-secondary' : null}
      components={{ Group }}
    />
  )
}
