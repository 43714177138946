import styles from './RelatedEvents.module.scss'
import SliderContainer from '../../SliderContainer'

export default function RelatedEvents({ section }) {
  const { title, events, description = null } = section
  if (events.length === 0) {
    return null
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <header className={styles.header}>
          {title && <h5 className={styles.title}>{title}</h5>}
          {description && <p className={styles.description}>{description}</p>}
        </header>
        <SliderContainer events={events} />
      </div>
    </section>
  )
}
