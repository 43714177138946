import { useRouter } from 'next/router'
import { setGeneralInquiry } from 'lib/services/inquiry'
import { savePagination } from 'lib/services/pagination'
import { frontPageSearchEvent } from 'lib/services/gtm'
import EventFilters from 'components/EventFilters'
import styles from './SearchBar.module.scss'

export default function SearchBar({ section }) {
  const router = useRouter()
  const { filters } = section

  /**
   * Callback for filter component "Search" button
   * Wraps up selections in store & redirects to events.
   *
   * Pass search data to GTM.
   */
  const handleSearch = (data) => {
    setGeneralInquiry(data)
    savePagination(1)
    frontPageSearchEvent(data)
    router.push('/tapahtumat')
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <EventFilters
          filters={filters}
          callback={handleSearch}
          design="secondary"
        />
      </div>
    </section>
  )
}
