import { useEffect } from 'react'
import { makeEmbedsResponsive } from 'lib/utils/responsive-embeds'
import styles from './ContentArea.module.scss'

export default function ContentArea({ section }) {
  const { content } = section

  /**
   * Run once on component load
   */
  useEffect(() => {
    const sectionVideoQuery = `.${styles.section} iframe[src*="youtube"], .${styles.section} iframe[src*="vimeo"]`
    const embedVideos = document.querySelectorAll(sectionVideoQuery)
    makeEmbedsResponsive(embedVideos)
  }, [section])

  return (
    <section className={styles.section}>
      <div className="container">
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </section>
  )
}
