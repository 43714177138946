export const sliderSettings = {
  dots: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  infinite: true,
  className: 'slick-no-margins',
}

export default { sliderSettings }
