import { useRouter } from 'next/router'
import { setGeneralInquiry } from 'lib/services/inquiry'
import { savePagination } from 'lib/services/pagination'
import { frontPageSearchEvent } from 'lib/services/gtm'
import Image from 'components/Image'
import EventFilters from 'components/EventFilters'
import styles from './Header.module.scss'

export default function Header({ section }) {
  const router = useRouter()
  const { title, subtitle, filters, links, image } = section

  /**
   * Callback for filter component "Search" button
   * Wraps up selections in store & redirects to events.
   *
   * Pass search data to GTM.
   */
  const handleSearch = (data) => {
    setGeneralInquiry(data)
    savePagination(1)
    frontPageSearchEvent(data)
    router.push('/tapahtumat')
  }

  return (
    <section className={styles.section}>
      <div className={styles.imgWrap}>
        <div className={styles.mobileImg}>
          <Image
            image={image}
            size="page-hero-mobile-retina"
            layout="fill"
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
        <div className={styles.desktopImg}>
          <Image
            image={image}
            size="page-hero-fp-retina"
            layout="fill"
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
      </div>

      <div className={styles.contentWrap}>
        <div className="container">
          <header className={styles.header}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </header>

          <EventFilters
            filters={filters}
            callback={handleSearch}
            useStore={false}
          />

          {links && (
            <div className={styles.links}>
              {links.map((link, index) => (
                <a
                  href={link.link}
                  className={styles.link}
                  key={`header-link-${index}`}
                >
                  {link.title}
                </a>
              ))}
            </div>
          )}
        </div>
        <img
          className={styles.decoration}
          alt=""
          role="presentation"
          src="/img/happens.svg"
        />
      </div>
    </section>
  )
}
