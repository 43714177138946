import styles from './LatestEvents.module.scss'
import SliderContainer from '../../SliderContainer'

export default function LatestEvents({ section }) {
  const { title, description, events } = section
  if (events.length === 0) {
    return null
  }
  return (
    <section className={styles.section}>
      <div className="container">
        <header className={styles.header}>
          {title && (
            <h2 className={`gradient-text ${styles.title}`}>{title}</h2>
          )}
          {description && <p className={styles.description}>{description}</p>}
        </header>
        <SliderContainer events={events} />
      </div>
    </section>
  )
}
