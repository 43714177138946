import { useState, useEffect } from 'react'
import { makeEmbedsResponsive } from 'lib/utils/responsive-embeds'
import { useForm } from 'react-hook-form'
import { mapFormData, submitForm } from 'lib/services/forms/client'
import contentStyles from 'components/Section/ContentArea/ContentArea.module.scss'
import styles from './Contact.module.scss'

export default function Contact({ section }) {
  const [errors, setErrors] = useState([])
  const [isSuccess, setIsSuccess] = useState(false)
  const { register, handleSubmit } = useForm()
  const { content } = section

  /**
   * Run once on component load
   */
  useEffect(() => {
    const sectionVideoQuery = `.${styles.section} iframe[src*="youtube"], .${styles.section} iframe[src*="vimeo"]`
    const embedVideos = document.querySelectorAll(sectionVideoQuery)
    makeEmbedsResponsive(embedVideos)
  }, [section])

  /**
   * Mapping of form values to labels.
   */
  const nameToLabelMap = {
    name: 'Nimi',
    email: 'Sähköpostiosoite',
    phone: 'Puhelinnumero',
    company: 'Yritys',
    message: 'Viesti',
  }

  const checkForContent = (data) => {
    let hasContent = false
    Object.values(data).forEach((value) => {
      if (value && value !== '') {
        hasContent = true
      }
    })

    return hasContent
  }

  const onSubmit = async (data) => {
    const hasContent = checkForContent(data)

    if (hasContent) {
      const formData = mapFormData(data, nameToLabelMap)
      const { valid, errors: requestErrors } = await submitForm(formData)

      if (valid) {
        setIsSuccess(true)
        setErrors([])
      } else {
        setErrors(requestErrors)
        setIsSuccess(false)
      }
    } else {
      setErrors(['Tyhjää yhteydenottoa ei voi lähettää'])
    }
  }

  return (
    <section className={styles.section} id="ota-yhteytta">
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.content}>
            <div
              className={contentStyles.content}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className={styles.form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="name">{nameToLabelMap.name}</label>
              <input
                placeholder={nameToLabelMap.name}
                id="name"
                {...register('name')}
              />

              <label htmlFor="email">{nameToLabelMap.email}</label>
              <input
                placeholder={nameToLabelMap.email}
                id="email"
                {...register('email')}
              />

              <label htmlFor="phone">{nameToLabelMap.phone}</label>
              <input
                placeholder={nameToLabelMap.phone}
                id="phone"
                {...register('phone')}
              />

              <label htmlFor="company">{nameToLabelMap.company}</label>
              <input
                placeholder={nameToLabelMap.company}
                id="company"
                {...register('company')}
              />

              <label htmlFor="message">{nameToLabelMap.message}</label>
              <textarea
                className={styles.message}
                placeholder={nameToLabelMap.message}
                id="message"
                {...register('message')}
              />
              <input className={styles.submit} type="submit" value="Lähetä" />
            </form>

            {isSuccess && <p>Kiitos! Yhteydenotto lähetetty onnistuneesti</p>}

            {!isSuccess &&
              errors.length > 0 &&
              errors.map((error, index) => (
                <p key={`error-${index}`} className={styles.error}>
                  {error}
                </p>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}
