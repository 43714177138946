import store from 'lib/services/store'

const DEFAULT_PAGINATION = 1

/**
 * Get active pagination value from store.
 */
export const getActivePagination = () => {
  if (store.isAvailable()) {
    const result = store.getPagination()

    if (!result) {
      return DEFAULT_PAGINATION
    }

    return result
  }

  return DEFAULT_PAGINATION
}

/**
 * Save active filters to store. Replaces old ones.
 */
export const savePagination = (page) => {
  if (store.isAvailable()) {
    store.setPagination(page)
  }
}

export default {
  getActivePagination,
}
