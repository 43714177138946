import { hasProperty } from 'spyrjari'

// Sectioned components.
import Categories from 'components/Section/Categories'
import Contact from 'components/Section/Contact'
import ContentArea from 'components/Section/ContentArea'
import CTA from 'components/Section/CTA'
import DefaultHero from 'components/Section/DefaultHero'
import FeaturedEvents from 'components/Section/FeaturedEvents'
import FiftyFifty from 'components/Section/FiftyFifty'
import FiftyFiftyHero from 'components/Section/FiftyFiftyHero'
import Header from 'components/Section/Header'
import LatestEvents from 'components/Section/LatestEvents'
import Steps from 'components/Section/Steps'
import IntroColumns from 'components/Section/IntroColumns'
import Plans from 'components/Section/Plans'
import FeaturesTable from 'components/Section/FeaturesTable'
import SearchBar from 'components/Section/SearchBar'
import Newsletter from 'components/Section/Newsletter'
import NewsletterModifiable from 'components/Section/NewsletterModifiable'
import HubspotContactForm from '../Section/HubspotContactForm'

const components = {
  Categories,
  Contact,
  ContentArea,
  CTA,
  DefaultHero,
  FeaturedEvents,
  FiftyFifty,
  Header,
  LatestEvents,
  Steps,
  FiftyFiftyHero,
  IntroColumns,
  Plans,
  FeaturesTable,
  SearchBar,
  Newsletter,
  NewsletterModifiable,
  HubspotContactForm,
}

export default function SectionedPage({ page }) {
  /**
   * Get sectioned fields.
   */
  const getSections = () => {
    let sectionedFields = []

    if (page?.fields?.sections) {
      sectionedFields = page.fields.sections
    }

    return sectionedFields
  }

  const sections = getSections(page)

  return (
    <>
      {sections.length > 0 &&
        sections.map((section, index) => {
          if (hasProperty(components, section.acf_fc_layout)) {
            const Component = components[section.acf_fc_layout]

            return (
              <Component
                section={section}
                key={`${section.acf_fc_layout}-${index}`}
              />
            )
          }

          return null
        })}
    </>
  )
}
